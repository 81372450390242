import { render, staticRenderFns } from "./create_menu.vue?vue&type=template&id=3516c1ac"
import script from "./create_menu.vue?vue&type=script&lang=js"
export * from "./create_menu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports